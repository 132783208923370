import { connect } from 'react-redux'
import React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const BotIcon = ({ config, classes, imgClassName = '' }) => (
  <div className={classes.root}>
    <img
      src={`${config.assets.rootCdn}/${config.assets.icons.botIcon}`}
      alt=""
      className={classNames(classes.img, imgClassName)}
    />
  </div>
)

export default connect(state => ({
  config: state.config,
}))(
  injectSheet(theme => ({
    root: {
      width: '2rem',
      marginRight: '.5rem',
      position: 'relative',

      ...theme.botIcon.root,
    },

    img: {
      width: '2rem',
      height: '2rem',
      position: 'absolute',
      bottom: 0,
      transition: '.3s',
      opacity: props => (props.isLast ? 1 : '.5'),

      ...theme.botIcon.img,
    },
  }))(BotIcon),
)
