import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'

class Message extends React.Component {
  state = {
    animate_enter: true,
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        animate_enter: false,
      })
    }, 100)
  }

  render() {
    const { message, render, isSharp, classes } = this.props,
      { animate_enter } = this.state

    return (
      <div
        className={classNames(
          classes.message,
          message.from === 'bot' ? classes.bot : classes.user,
          isSharp
            ? message.from === 'bot'
              ? classes.botExceptFirstMessage
              : classes.userExceptFirstMessage
            : '',
          animate_enter
            ? message.from === 'bot'
              ? classes.botEnterAnimation
              : classes.userEnterAnimation
            : '',
        )}
      >
        {render()}
      </div>
    )
  }
}

export default connect(state => ({
  config: state.config,
}))(
  injectSheet(theme => ({
    message: {
      padding: '1.4rem',
      paddingRight: 0,
      paddingLeft: 0,
      borderRight: '1.4rem solid rgba(0, 0, 0, 0)',
      borderLeft: '1.4rem solid rgba(0, 0, 0, 0)',
      lineHeight: '1.5',
      marginBottom: '.55rem',
      borderRadius: '1rem',
      transition: 'all 100ms ease-in',
      opacity: 1,
      transform: 'translate(0)',
      position: 'relative',
      maxWidth: '100%',
      boxSizing: 'border-box',
      overflow: 'hidden',
      color: '#ffffff',
      
      '&:last-child': {
        marginBottom: 0,
      },

      ...theme.message,
    },

    bot: {
      backgroundColor: '#5bc2e7',
      borderBottomLeftRadius: '.25rem',
      fallbacks: {
        backgroundColor: '#cedccd',
      },

      ...theme.botMessage,
    },

    botExceptFirstMessage: {
      borderTopLeftRadius: '.25rem',

      ...theme.botExceptFirstMessage,
    },

    user: {
      backgroundColor: '#64ccc9',
      borderBottomRightRadius: '.25rem',
      marginLeft: 'auto',
      fallbacks: {
        backgroundColor: '#e4efe3',
      },

      ...theme.userMessage,
    },

    userExceptFirstMessage: {
      borderTopRightRadius: '.25rem',

      ...theme.userExceptFirstMessage,
    },

    userEnterAnimation: {
      opacity: 0,
      transform: 'translate(100px)',

      ...theme.userMessageEnterAnimation,
    },

    botEnterAnimation: {
      opacity: 0,
      transform: 'translate(-100px)',

      ...theme.botMessageEnterAnimation,
    },

    leaveAnimation: {
      opacity: 0,
      transform: 'translateY(100px)',

      ...theme.messageLeaveAnimation,
    },
  }))(Message),
)
