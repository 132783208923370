import React, { Fragment } from 'react'

export default function withLineBreak(text) {
  if (Array.isArray(text) && text.length > 1) {
    return (
      <Fragment>
        {text.split('\n').map((item, i) => (
          <Fragment key={i}>
            {item}
            <br />
          </Fragment>
        ))}
      </Fragment>
    )
  }

  return text
}
