import React from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { getStyles } from '../styles'
import Input from './Input'
import PostButton from './PostButton'

class InputWithButton extends React.Component {
  /**
   *
   */
  render() {
    const {
      classes,
      className = '',
      inputClassName = '',
      buttonClassName = '',
      handleChange,
      handleKeyPress,
      value,
      handleClick,
      handleBlur,
      handleKeyDown,
      handleFocus,
      placeholder,
      ariaActivedescendant,
      ariaAutocomplete,
      ariaControls,
      autoComplete,
      inputKey,
      inputRef,
      text,
    } = this.props

    return (
      <div className={classNames(classes.root, className)}>
        <Input
          inputRef={inputRef}
          className={classNames(classes.input, inputClassName)}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          value={value}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          placeholder={placeholder}
          aria-activedescendant={ariaActivedescendant}
          aria-autocomplete={ariaAutocomplete}
          aria-controls={ariaControls}
          autoComplete={autoComplete}
          key={inputKey}
          text={text}
        />
        <PostButton className={classNames(classes.postButton, buttonClassName)} onClick={handleClick} />
      </div>
    )
  }
}

export default connect()(
  injectSheet(theme => ({
    root: {
      display: 'flex',
      border: `1px solid ${getStyles('borderColor')}`,
      borderRadius: getStyles('borderRadius'),
      padding: '.5rem',
      width: '100%',
      boxSizing: 'border-box',

      ...theme.inputWithButton.root,
    },

    input: {
      border: 'none',
      borderTopLeftRadius: getStyles('borderRadius'),
      borderBottomLeftRadius: getStyles('borderRadius'),
      width: '100%',
      backgroundColor: 'transparent',

      '&:focus': {
        outline: 'none',
      },

      ...theme.inputWithButton.input,
    },

    postButton: {
      borderTopRightRadius: getStyles('borderRadius'),
      borderBottomRightRadius: getStyles('borderRadius'),
      minHeight: 'unset',

      ...theme.inputWithButton.button,
    },
  }))(InputWithButton),
)
