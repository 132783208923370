import reducer from './reducer'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import styles from './styles'

const initialState = {
  /* eslint-disable-next-line */
  config: WhatbotConfig,
  messages: [],
  posting: false,
  styles,
}

let composeResults

if (process.env.NODE_ENV === 'production') {
  composeResults = compose(applyMiddleware(thunk))
} else {
  composeResults = compose(
    applyMiddleware(require('redux-immutable-state-invariant').default(), thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  )
}

const store = createStore(reducer, initialState, composeResults)

export default store
