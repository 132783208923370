import React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import { connect } from 'react-redux'

const styles = theme => ({
  post: {
    backgroundImage: props => {
      const assets = props.config.assets

      return `url(${assets.rootCdn}/${assets.icons.send})`
    },
    width: '2rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '1.3rem',
    transition: '.3s',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    minHeight: '2rem',

    ...theme.postButton,
  },
})

const PostButton = ({ onClick, classes, className = '' }) => (
  <button className={classNames(classes.post, className)} onClick={onClick} />
)

export default connect(state => ({
  config: state.config,
}))(injectSheet(styles)(PostButton))
