import { connect } from 'react-redux'
import * as React from 'react'
import nanoid from 'nanoid'
import OutsideClickHandler from 'react-outside-click-handler'
import injectSheet from 'react-jss'

import { getStyles } from './styles'
import { userSaidWithoutRemovingLastMessageAndPost } from './action'
import InputWithButton from './Components/InputWithButton'

let panelHeight = 0

class Panel extends React.Component {
  panelRef

  state = {
    show_menu_items: false,
    post_data: {
      query: '',
    },
  }

  componentDidMount() {
    panelHeight = this.panelRef.clientHeight
  }

  /**
   *
   */
  handleChange = e => {
    const { value } = e.target

    this.setState(prevState => ({
      post_data: {
        query: value,
      },
    }))
  }

  /**
   *
   */
  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.dispatch()
    }
  }

  /**
   *
   */
  handleShowMenuItems = () => {
    this.setState(p => ({
      show_menu_items: !p.show_menu_items,
    }))
  }

  /**
   *
   */
  dispatch = () => {
    if (this.state.post_data.query.trim()) {
      this.props.dispatch(userSaidWithoutRemovingLastMessageAndPost(this.state.post_data.query, this.state.post_data))

      this.setState(p => ({
        post_data: {
          ...p.post_data,
          query: '',
        },
      }))
    }
  }

  /**
   * Restart the widget.
   */
  refresh = () => {
    window.location.reload()
  }

  /**
   *
   */
  handleMenuItemClick = item => {
    this.setState(
      {
        show_menu_items: false,
        post_data: {
          query: item,
        },
      },
      () => this.dispatch(),
    )
  }

  /**
   *
   */
  getMenuItem = item => {
    if (item === '--') {
      return <hr key={nanoid()} className={this.props.classes.menuHr} />
    }

    return (
      <div key={nanoid()} className={this.props.classes.menuItem} onClick={() => this.handleMenuItemClick(item)}>
        {item}
      </div>
    )
  }

  /**
   *
   */
  handleOutsideMenuClick = () => {
    this.setState({
      show_menu_items: false,
    })
  }

  /**
   *
   */
  render() {
    const { config, classes } = this.props,
      { show_menu_items } = this.state

    return (
      <div className={classes.root} ref={el => (this.panelRef = el)}>
        <OutsideClickHandler onOutsideClick={this.handleOutsideMenuClick}>
          <div className={classes.menu}>
            {show_menu_items && (
              <div className={classes.menuItems}>{config.panel.menu.map(item => this.getMenuItem(item))}</div>
            )}
            <button onClick={this.handleShowMenuItems} className={this.props.classes.menuIcon} />
          </div>
        </OutsideClickHandler>

        <InputWithButton
          handleChange={this.handleChange}
          inputRef={el => (this.inputRef = el)}
          handleKeyPress={this.handleKeyPress}
          value={this.state.post_data.query}
          handleClick={this.dispatch}
          className={classes.inputWithButton}
          placeholder={config.panel.input.placeholder}
          inputClassName={classes.input}
          buttonClassName={classes.sendIcon}
        />

        <button className={this.props.classes.refreshIcon} onClick={this.refresh} />
      </div>
    )
  }
}

export default connect(state => ({
  config: state.config,
}))(
  injectSheet(theme => ({
    root: {
      flexShrink: 0,
      display: 'flex',
      width: '100%',
      padding: '.8rem .5rem',
      boxSizing: 'border-box',
      borderTop: `1px solid ${getStyles('borderColor')}`,

      ...theme.panel.root,
    },

    menu: {
      position: 'relative',
      height: '100%',

      ...theme.panel.menu,
    },

    menuItems: {
      position: 'absolute',
      backgroundColor: 'white',
      bottom: props => `${panelHeight}px`,
      whiteSpace: 'nowrap',
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
      borderRadius: '.25rem',

      ...theme.panel.menuItems,
    },

    menuHr: {
      margin: 0,

      ...theme.panel.menuHr,
    },

    menuItem: {
      padding: '1rem',
      cursor: 'pointer',
      transition: '.3s',

      '&:hover': {
        backgroundColor: '#00000014',
      },

      ...theme.panel.menuItem,
    },

    refreshIcon: {
      backgroundImage: props => {
        const assets = props.config.assets

        return `url(${assets.rootCdn}/${assets.icons.refresh})`
      },
      width: '3rem',
      backgroundColor: 'transparent',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '100%',
      cursor: 'pointer',
      padding: 0,
      border: 'none',

      ...theme.panel.refreshIcon,
    },

    menuIcon: {
      backgroundImage: props => {
        const assets = props.config.assets

        return `url(${assets.rootCdn}/${assets.icons.menu})`
      },
      width: '3rem',
      height: '100%',
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      cursor: 'pointer',
      transition: '.3s',
      marginRight: '1rem',
      backgroundColor: 'transparent',
      border: 'none',

      ...theme.panel.menuIcon,
    },

    inputWithButton: {
      marginRight: '1rem',

      ...theme.panel.inputWithButton,
    },

    input: {
      '&::placeholder': {
        color: getStyles('borderColor'),
      },

      ...theme.panel.input,
    },

    sendIcon: {
      ...theme.panel.sendIcon,
    },
  }))(Panel),
)
