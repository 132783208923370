import { connect } from 'react-redux'
import React, { Suspense } from 'react'
import injectSheet from 'react-jss'
import _ from 'lodash'
import ThumbsComponent  from './ThumbsComponent'

import {
  MESSAGE_AUTOFILL,
  MESSAGE_BUTTON,
  MESSAGE_CHECKBOX,
  MESSAGE_EMOJI,
  MESSAGE_HTML,
  MESSAGE_IMAGE,
  MESSAGE_INPUT,
  MESSAGE_MAP,
  MESSAGE_TABLE,
  MESSAGE_GEOLOC,
  MESSAGE_CAROUSEL,
} from './action'

import Message from './Message'
import TextMessage from './MessageTypes/TextMessage'
import WithChunk from './WithChunk'

const ButtonMessage = React.lazy(() => import('./MessageTypes/ButtonMessage')),
  AutofillMessage = React.lazy(() => import('./MessageTypes/AutofillMessage')),
  CheckboxMessage = React.lazy(() => import('./MessageTypes/CheckboxMessage')),
  EmojiMessage = React.lazy(() => import('./MessageTypes/EmojiMessage')),
  HtmlMessage = React.lazy(() => import('./MessageTypes/HtmlMessage')),
  ImageMessage = React.lazy(() => import('./MessageTypes/ImageMessage')),
  InputMessage = React.lazy(() => import('./MessageTypes/InputMessage')),
  MapMessage = React.lazy(() => import('./MessageTypes/MapMessage')),
  TableMessage = React.lazy(() => import('./MessageTypes/TableMessage')),
  GeolocMessage = React.lazy(() => import('./MessageTypes/GeolocMessage')),
  CarouselMessage = React.lazy(() => import('./MessageTypes/CarouselMessage'))

class Chunk extends React.Component {
  state = {
    messages: [],
    index: -1,
  }

  /**
   *
   */
  componentDidMount() {
    const { messages, nbChunks, index } = this.props

    if (index === nbChunks - 1) {
      this.animateMessages(messages, 0)
    }
  }

  /**
   *
   */
  animateMessages = (messages, i) => {
    const { config, scrollToBottom } = this.props

    if (messages[i] !== undefined) {
      this.setState(p => ({
        messages: p.messages.concat(messages[i]),
      }))

      setTimeout(() => {
        this.animateMessages(messages, i + 1)
      }, config.animation_timeouts.wait_between_messages_ms)
    }

    scrollToBottom()
  }

  /**
   *
   */
  wrap = (chunk, inner) => {
    const { classes, nbChunks, index } = this.props,
      has_bot = chunk.data.length > 0 && chunk.from !== 'user',
      is_bot_last = nbChunks === index + 1

    return (
      <WithChunk
        component={inner}
        isBotLast={is_bot_last}
        hasBot={has_bot}
        className={chunk.from === 'user' ? classes.chunkUser : ''}
      />
    )
  }

  /**
   *
   */
  render() {
    const { chunk, nbChunks, index, logs_meta } = this.props
    let messages = []

    if (index === nbChunks - 1) {
      messages = this.state.messages
    } else {
      messages = this.props.messages
    }

    if (!messages.length) {
      return []
    }
    // console.log(messages);
    var textRate = messages[0]['phrase']
    let rateBtns = ''
    let msg =  messages[0]

    if(typeof msg.intent!=='undefined'){
      var sentQuery = messages[0]['sentQuery']
      var intentName = msg['intent']['displayName']
      let isNotDefault = ![
      "Default Fallback Intent",
      "Q_AND_A__Thumbs down__1597217023",
      "Q_AND_A__Thumbs up__1597217069",
      "Q_AND_A__salut__1575299949",
      "Default Welcome Intent",
      "Q_AND_A__mariage__1575301404",
      "Q_AND_A__Remerciement__1595266078",
      "Q_AND_A__grateful__1595266129"
      ].includes(intentName)

      if (isNotDefault) {
        // console.log('entrééééé');
        rateBtns = <ThumbsComponent texte={textRate} intentName={sentQuery} />
      }
    }
    let varIndex = messages.length

    return this.wrap(chunk, () => {
      return messages.map((_message, index) => {
        // console.log('Messsage numero --------'+ index + '/'+(varIndex-1));
        let displayRateBtn = index == varIndex -1 ? rateBtns : '';
        const message = { ..._message }

        if (!('from' in message)) {
          message.from = 'bot'
        }

        if (
          message.from === 'bot'
          // && logs_meta.is_stib
          && _.hasIn(message, 'text')
          && _.hasIn(message.text, 'text')
          && message.text.text.length > 0
          && _.startsWith(message.text.text[0], '{"')
        ) {
          let attempts = 0

          while (attempts++ < 3) {
            try {
              message.payload = JSON.parse(message.text.text[0])
              break
            } catch (e) {
              if (e instanceof SyntaxError) {
                if (
                  !_.endsWith(message.text.text[0], '"')
                  && !_.endsWith(message.text.text[0], '}')
                ) {
                  message.text.text[0] = message.text.text[0] + '"'
                }

                message.text.text[0] = message.text.text[0] + '}'
              } else {
                console.error(e)
              }
            }
          }

          delete message.text
        }

        if (_.hasIn(message, 'payload')) {
          const { payload } = message

          const types = {
            [MESSAGE_CHECKBOX]: CheckboxMessage,
            [MESSAGE_INPUT]: InputMessage,
            [MESSAGE_MAP]: MapMessage,
            [MESSAGE_TABLE]: TableMessage,
            [MESSAGE_BUTTON]: ButtonMessage,
            [MESSAGE_IMAGE]: ImageMessage,
            [MESSAGE_HTML]: HtmlMessage,
            [MESSAGE_EMOJI]: EmojiMessage,
            [MESSAGE_AUTOFILL]: AutofillMessage,
            [MESSAGE_GEOLOC]: GeolocMessage,
            [MESSAGE_CAROUSEL]: CarouselMessage,
          }

          if (Object.keys(types).indexOf(payload.type) !== -1) {
            const { type, ...data } = payload

            return (
              <Suspense fallback={<div />} key={message.key}>
                <Message
                  message={message}
                  render={() => React.createElement(types[type], { ...data })}
                  isSharp={index > 0}
                />
              </Suspense>
            )
          }

          throw new Error(`Unsupported message payload type: '${payload.type}'`)
        }

        if (_.hasIn(message, 'text')) {
          return (
            <Message
              key={message.key}
              message={message}
              render={() => <TextMessage content={message.text} />}
              isSharp={index > 0}
            />
          )
        }


        throw new Error(`Unsupported message type: '${message.type}'`)
      })
    })
  }
}

export default connect(state => ({
  config: state.config,
  logs_meta: state.logs_meta,
}))(
  injectSheet(theme => ({
    root: {
      display: 'flex',
      paddingBottom: '1rem',
    },

    chunkUser: {
      flexDirection: 'row-reverse',
    },
  }))(Chunk),
)
