import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'

import Chunk from './Chunk'
import Loading from './Components/Loading'

const Messages = ({ messages, posting, classes }) => {
  const messages_end_ref = useRef(null)

  const scrollToBottom = () => {
    messages_end_ref.current.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, posting])

  return (
      <div className={classes.root}>
        <div>
          {messages.map((chunk, chunk_index) => (
            <Chunk
              key={chunk.key}
              chunk={chunk}
              messages={chunk.data}
              index={chunk_index}
              nbChunks={messages.length}
              scrollToBottom={scrollToBottom}
            />
          ))}

          {posting && <Loading has_chunk={true} />}
        </div>

        <div ref={messages_end_ref} />
      </div>
  )
}

export default connect(state => ({
  messages: state.messages,
  posting: state.posting,
}))(
  injectSheet(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: '100%',
      padding: '1rem',
      scrollBehavior: 'smooth',
    },
  }))(Messages),
)
