import {
  USER_SAID,
  SET_CONFIG,
  POSTING,
  POST_FAILED,
  POST_SUCCESS,
  REMOVE_LAST_MESSAGE,
  SET_LOGS_META,
} from './action'

const reducer = (state, action) => {
  switch (action.type) {

    case SET_LOGS_META: {
      return {
        ...state,
        logs_meta: action.payload,
      }
    }

    // ----------------------------------------------- REMOVE_LAST_MESSAGE --
    case REMOVE_LAST_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages
            .map((message, index) => {
              if (index === state.messages.length - 1) {
                message = {
                  ...message,
                  data: message.data.slice(0, message.data.length - 1),
                }
              }

              return message
            })
            .filter(message => message.data.length > 0),
        ],
      }

    // -------------------------------------------------------- SET_CONFIG --
    case SET_CONFIG:
      return {
        ...state,
        config: { ...action.config },
      }

    // --------------------------------------------------------- USER_SAID --
    case USER_SAID:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      }

    // ----------------------------------------------------------- POSTING --
    case POSTING:
      return {
        ...state,
        posting: true,
        post_data: {},
        rendering_messages: true,
      }

    // ------------------------------------------------------ POST_SUCCESS --
    case POST_SUCCESS:
      return {
        ...state,
        posting: false,
        messages: [...state.messages, action.payload],
      }

    // ------------------------------------------------------- POST_FAILED --
    case POST_FAILED:
      return {
        ...state,
        posting: false,
        post_errors: {
          ...action,
        },
      }

    default:
      return state
  }
}

export default reducer
