import React, { Component }  from 'react';
// import ReactDOM from 'react-dom';
import { ThumbComponent } from './ThumbComponent';
import { connect } from 'react-redux'
import { userReacted, userSaidWithoutRemovingLastMessageAndPost, userSaid } from './action.js'

const styles ={ 
    float: 'right',
    color: 'red',
    color: '#6c757d',
    fontStyle: 'italic',
    fontSize: '0.85em',
    paddingLeft : 5,
}
class ThumbsComponent extends Component{
    constructor(props) {
        super(props);
        this.state = { liked: false, unliked: false, deactivated: false};
        this.toggleLike = this.toggleLike.bind(this);
        this.toggleNotLike = this.toggleNotLike.bind(this);
    }
    componentWillMount(){
        // this.props.userSaid('Hello');
    }
    toggleLike() {
        this.state.deactivated = true;
        let newV = !this.state.liked;
        this.setState({ liked: newV, unliked:false });
        this.props.userReacted('Hello',{query : 'thumb up',reaction: 1, sentQuery : this.props.intentName});

    } 
    toggleNotLike() {
        this.state.deactivated = true;
        let newV = !this.state.unliked;
        this.setState({ unliked : newV, liked : false});
        console.log('je sui lancé ooooo');
        this.props.userReacted('Hello',{query : 'thumb down',reaction: 0, sentQuery : this.props.intentName});
    } 
    render(){
        return (<span style={styles}>
            {this.props.texte}
            <ThumbComponent onClickLikeBtn={this.toggleLike} deact={this.state.deactivated} clicked={this.state.liked} direction="up" />
            <ThumbComponent onClickLikeBtn={this.toggleNotLike} deact={this.state.deactivated} clicked={this.state.unliked} direction="down" />
        </span>);
    }
}
export default connect(null, { userReacted })(ThumbsComponent);