import 'react-app-polyfill/ie9'
import "@babel/polyfill"
import { Provider } from 'react-redux'
import React from 'react'
import { render } from 'react-dom'
import { ThemeProvider } from 'react-jss'
import smoothscroll from 'smoothscroll-polyfill'

import store from './store'
import Widget from './Widget'

smoothscroll.polyfill()

render(
  <Provider store={store}>
    {/* eslint-disable-next-line */}
    <ThemeProvider theme={WhatbotConfig.theme}>
      <Widget />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
)
