import React from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import classNames from 'classnames'

import { post } from './action'
import Messages from './Messages'
import Panel from './Panel'

class Widget extends React.Component {
  /**
   * Component did mount.
   */
  componentDidMount() {
    this.props.dispatch(post(this.getPostData()))
    this.loadFonts()
  }

  /**
   * Load user defined fonts.
   */
  loadFonts = () => {
    const { config } = this.props

    for (let i = 0; i < config.fonts.length; i++) {
      let linkEl = document.createElement('link')
      linkEl.setAttribute('rel', 'stylesheet')
      linkEl.setAttribute('type', 'text/css')
      linkEl.setAttribute('href', config.fonts[i])
      document.head.appendChild(linkEl)
    }
  }

  /**
   * Get post data.
   */
  getPostData = () => {
    const { config } = this.props

    return config.post
  }

  /**
   * render.
   */
  render() {
    const { config, classes } = this.props

    return (
      <div id="whatbot" className={classNames(classes.root)}>
        <Messages />
        {config.panel.visible && <Panel />}
      </div>
    )
  }
}

export default connect(state => ({
  config: state.config,
  messages: state.messages,
}))(
  injectSheet(theme => ({
    root: {
      backgroundImage: `linear-gradient(to top, #c1dfc4 0%, #deecdd 100%)`,
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      overflow: 'hidden',
      fontFamily: 'sans-serif',
      fontSize: '16px',
      width: '100%',
      height: '100%',

      ...theme.widget,
    },
  }))(Widget),
)
