import React from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import withLineBreak from '../Components/withLineBreak'
import _ from 'lodash'
import nanoid from 'nanoid'

const TextMessage = ({ classes, content }) => {
  if (!_.hasIn(content, 'text')) {
    return <div className={classes.root}>{withLineBreak(content)}</div>
  }

  return content.text.map(text => (
    <div key={nanoid()} className={classes.root}>{withLineBreak(text)}</div>
  ))
}

export default connect(state => ({
  config: state.config,
}))(
  injectSheet(theme => ({
    root: {
      ...theme.messageTypes.text,
    },
  }))(TextMessage),
)
