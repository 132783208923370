import store from './store'

const defaultStyles = {
  borderColor: '#9ab09b',
  borderRadius: '.25rem',

  button: {
    fontWeight: 600,
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: '5px',
    transition: 'all .3s',
    backgroundColor: '#5dc8cd',
    border: 0,
    cursor: 'pointer',
    fontSize: '.8rem',
    textTransform: 'uppercase',
    padding: '.7rem .8rem',
    textDecoration: 'none',
    marginTop: '.5rem',
    marginRight: '.5rem',

    '&:hover': {
      backgroundColor: '#01939a',
    },
  },
}

export function getStyles(key, themeObject = {}) {
  const state = store.getState(),
    { styles } = state.config

  if (!(key in defaultStyles)) {
    throw new Error(`Key '${key}' does not exists in defaultStyles`)
  }

  if (typeof defaultStyles[key] === 'string') {
    if (key in styles && typeof styles[key] === 'string' && styles[key].length > 0) {
      return styles[key]
    }

    return defaultStyles[key]
  }

  return {
    ...defaultStyles[key],
    ...styles[key],
    ...themeObject,
  }
}

export default defaultStyles
