import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

const styles={
    marginLeft : 4,
    marginRight : 4,
    cursor: 'pointer',

}
/**
 * fa thumbs from repository FortAwesome/react-fontawesome
 */
export class ThumbComponent extends React.Component{
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleColor = this.handleColor.bind(this);
    }
    handleColor(){
        if(this.props.clicked==true){
            return "clicked";
        }else{
            return "";
        }
    }
    handleChange(e) {
        if(this.props.deact) return;
        this.props.onClickLikeBtn();
    }
    render(){
        let position = this.props.direction=='up'? faThumbsUp : faThumbsDown;
        return <FontAwesomeIcon onClick={this.handleChange} className={this.handleColor()} style={styles} icon={position} />;
    }
}