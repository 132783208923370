import React from 'react'
import injectSheet from 'react-jss'

import Message from '../Message'
import WithChunk from '../WithChunk'

const stylesheet = document.styleSheets[0],
  animationName = 'waving-dots'

const keyFrames = `
  @keyframes ${animationName} {
    0%, 60%, 100% {
      transform: initial;
    }

    30% {
      transform: translateY(-15px);
    }
  }
`

stylesheet.insertRule(keyFrames, stylesheet.cssRules.length)

const Loading = ({ has_chunk, classes }) => {
  const component = () => (
    <Message
      message={{ from: 'bot' }}
      render={() => (
        <div className={classes.wavingDots}>
          <span className={classes.dot} />
          <span className={classes.dot} />
          <span className={classes.dot} />
        </div>
      )}
    />
  )

  if (has_chunk) {
    return <WithChunk component={component} />
  }

  return component()
}

export default injectSheet(theme => ({
  wavingDots: {
    ...theme.loading.wavingDots,
  },

  dot: {
    display: 'inline-block',
    width: '.5rem',
    height: '.5rem',
    borderRadius: '50%',
    marginRight: '.2rem',
    backgroundColor: '#333',
    animation: `${animationName} 1.3s linear infinite`,

    '&:nth-child(2)': {
      animationDelay: '-1.1s',
    },

    '&:nth-child(3)': {
      animationDelay: '-0.9s',
    },

    ...theme.loading.dot,
  },
}))(Loading)
