import React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const styles = theme => ({
  input: {
    fontSize: '1rem',

    ...theme.input,
  },
})

class Input extends React.Component {
  render() {
    const { classes, inputRef, className, ...inputProps } = this.props

    return (
      <input
        ref={inputRef}
        type="text"
        className={classNames(classes.input, className)}
        {...inputProps}
      />
    )
  }
}

export default injectSheet(styles)(Input)
