import React from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import classNames from 'classnames'

import BotIcon from './Components/BotIcon'

class WithChunk extends React.Component {
  render() {
    const {
      component,
      classes,
      isBotLast = true,
      hasBot = true,
      className = '',
    } = this.props

    return (
      <div className={classNames(classes.root, className)}>
        {hasBot && <BotIcon isLast={isBotLast} />}
        <div className={classes.list}>{component()}</div>
      </div>
    )
  }
}

export default connect(state => ({
  config: state.config,
}))(
  injectSheet(theme => ({
    root: {
      display: 'flex',
      paddingBottom: '1rem',
    },

    list: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 'calc(100% - (32px + 8px))',
    },
  }))(WithChunk),
)
